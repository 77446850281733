"use client";

import { Box, Button, Typography } from "@mui/material";
import Container from "./container";
import React from "react";

const Products = () => {
  return (
    <Container color>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: 15,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: { xs: "center", md: "end" },
            flex: 1,
            gap: 3,
            px: { xs: 2, md: 0 },
            maxWidth: 500,
          }}
        >
          <Typography variant="h1">PRODUTOS</Typography>
          <Typography variant="h4">
            A marca Uni Premium foi criada e planejada com uma seleção de
            matéria-prima de altíssima qualidade. Trazendo conforto, leveza e
            resistência sem abrir mão do estilo com seu design único e
            sofisticado
          </Typography>
          <Button
            variant="contained"
            sx={{ px: 6, "&:hover": { color: "white" } }}
          >
            Confira
          </Button>
        </Box>
        <Box
          display={"flex"}
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img alt="" src={"/Products.png"} width={400} height={400} />
        </Box>
      </Box>
    </Container>
  );
};

export default Products;

"use client";
import React from "react";
import { Box, Button, Typography } from "@mui/material";

const Header = () => {
  return (
    <Box
      sx={{
        width: "100%",
        py: 6,
        px: { xs: 5, md: 20 },
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Box
        maxWidth={1900}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Box sx={{ gap: 4, display: { xs: "none", md: "flex" } }}>
          <img
            alt="Instagram"
            src="/logoInsta.png"
            width={40}
            height={40}
            style={{ cursor: "pointer" }}
          />
          <img
            alt="Facebook"
            src="/logoFace.png"
            width={40}
            height={40}
            style={{ cursor: "pointer" }}
          />
          <img
            alt="WhatsApp"
            src="/logoZap.png"
            width={40}
            height={40}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Box zIndex={1}>
          <img alt="" src={"/logo.jpg"} width={90} height={90} />
        </Box>
        <Button
          sx={(theme) => ({
            border: `2px solid ${theme.palette.primary.main}`,
            borderTop: `3px solid ${theme.palette.primary.main}`,
            px: 6,
            "&:hover": { background: theme.palette.primary.main },
          })}
          color="secondary"
        >
          <Typography
            variant="h3"
            sx={{ textTransform: "none", color: "black" }}
          >
            Catálago
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Header;

"use client";

import { Box, Typography } from "@mui/material";
import Container from "./container";
import React from "react";

const VisionAndValues = () => {
  return (
    <Container color>
      <Box
        sx={{
          display: "flex",
          gap: { xs: 2, md: 5 },
          px: 2,
          justifyContent: "center",
        }}
      >
        <Box
          textAlign={"end"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            maxWidth: { xs: 200, md: 305 },
            gap: { xs: 2, md: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2em", md: "3em" },
            }}
          >
            VISÃO
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "1em", md: "1.2em" },
            }}
          >
            Manter-se como marca referência de mercado, ser líder nacional em
            distribuição e em satisfação dos clientes. Oferecendo assim o
            suporte necessário para entrega de produtos capazes de atender as
            necessidades de seu tempo, aliando produtividade e superação de
            metas, através da integridade dos valores humanos e consciência
            sustentável.
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{
            gap: { xs: 2, md: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2em", md: "3em" },
            }}
          >
            &
          </Typography>
          <Box display={"flex"} flexDirection={"row"} height="100%">
            <Box
              sx={(theme) => ({
                bgcolor: theme.palette.primary.main,
                width: 5,
                height: "100%",
              })}
            ></Box>
            <Box bgcolor={"white"} width={2} height={"100%"}></Box>
            <Box bgcolor={"#5B89A7"} width={5} height={"100%"}></Box>
            <Box bgcolor={"white"} width={10} height={"100%"}></Box>
          </Box>
        </Box>
        <Box
          textAlign={"start"}
          maxWidth={150}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            gap: { xs: 2, md: 5 },
            mr: { md: 20 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2em", md: "3em" },
            }}
          >
            VALORES
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { md: "1.58em" },
              lineHeight: { xs: 2.7, md: 1.5 },
            }}
          >
            Verdade Empatia Respeito Ética Responsabilidade Transparência
            Sustentabilidade
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default VisionAndValues;

import AboutUs from "./components/aboutUs";
import Container from "./components/container";
import Footer from "./components/footer";
import Header from "./components/header";
import Mission from "./components/mission";
import VisionAndValues from "./components/visionAndValues";
import { Box, Button, Typography } from "@mui/material";
import Products from "./components/products";

function App() {
  return (
    <>
      <Header />
      <Container color>
        <Box
          sx={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            gap: 3,
            flexDirection: "column",
            maxWidth: 500,
          }}
        >
          <Typography variant="h1">
            ESTILO E CONFORTO EM CADA PAR DE MEIAS
          </Typography>
          <Typography variant="h4">
            Encontre os melhores produtos em nosso catálogo
          </Typography>
          <Button
            variant="contained"
            sx={{ px: 6, "&:hover": { color: "white" } }}
          >
            Confira
          </Button>
        </Box>
      </Container>
      <AboutUs />
      <Products />
      <Mission />
      <VisionAndValues />
      <Footer />
    </>
  );
}

export default App;

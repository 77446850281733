import "../fonts/fonts.css";
import { createTheme } from "@mui/material/styles";

const PRIMARY = "#97D0DA";
const SECONDARY = "#F8F8F8";
const SUCCESS = "#2eca45";
const TEXT = "#000";

const theme = createTheme({
  palette: {
    primary: { main: PRIMARY },
    secondary: { main: SECONDARY },
    success: { main: SUCCESS },
    text: { primary: TEXT },
  },
  typography: {
    fontFamily: '"Goldplay Medium", "Goldplay Bold", Arial, sans-serif',
    h1: {
      fontSize: "3em",
      fontWeight: 700,
      fontFamily: "Goldplay Bold",
    },
    h2: {
      fontSize: "1.5em",
      fontWeight: 700,
      fontFamily: "Goldplay Bold",
    },
    h3: {
      fontSize: "1em",
      fontWeight: 700,
      fontFamily: "Goldplay Medium",
    },
    h4: {
      fontSize: "1.2em",
      fontWeight: 500,
      fontFamily: "Goldplay Medium",
    },
  },
});

export default theme;

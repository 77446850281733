"use client";
import React from "react";

import { Box, Typography } from "@mui/material";

import Container from "./container";

const AboutUs = () => {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: "center",
          gap: 15,
          justifyContent: "space-between",
        }}
      >
        <Box
          display={"flex"}
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img alt="" src="/AboutUs.png" width={400} height={400} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: { xs: "center", md: "start" },
            flex: 1,
            gap: 3,
            px: { xs: 2, md: 0 },
            maxWidth: 500,
          }}
        >
          <Typography variant="h1">SOBRE NÓS</Typography>
          <Typography variant="h4">
            Atuamos no atacado e varejo com a distribuição de meias de grandes
            marcas nacionais. Nosso principal objetivo é levar até o cliente
            produtos de qualidade com preços e prazos competitivos através da
            entrega confiável e segura, diferencial este que torna a UNI uma das
            maiores empresas das regiões neste segmento comercial.
          </Typography>
          <Typography variant="h4">
            Nossa missão é fornecer ao cliente a melhor qualidade através das
            meias que acompanham as tendências da moda, do clássico ao moderno,
            do básico ao sofisticado, sem abrir mão do conforto e da
            versatilidade, pois podem ser usadas em diversas ocasiões e compor
            vários looks tanto para homens quanto para mulheres e crianças.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutUs;

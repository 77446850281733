"use client";
import React from "react";
import { Box } from "@mui/material";

const Footer = () => {
  return (
    <Box
      width={"100%"}
      py={3}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        gap={5}
      >
        <img
          alt="Instagram"
          src="/logoInsta.png"
          width={40}
          height={40}
          style={{ cursor: "pointer" }}
        />
        <img
          alt="Facebook"
          src="/logoFace.png"
          width={40}
          height={40}
          style={{ cursor: "pointer" }}
        />
        <img
          alt="WhatsApp"
          src="/logoZap.png"
          width={40}
          height={40}
          style={{ cursor: "pointer" }}
        />
      </Box>
    </Box>
  );
};

export default Footer;

"use client";

import React from "react";
import { Box } from "@mui/material";

const Container = ({ children, color }) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundImage: color
          ? `linear-gradient(to bottom, ${theme.palette.secondary.main}, rgba(0, 0, 0, 0.05))`
          : "",
        width: "100%",
        justifyContent: "center",
        display: "flex",
        py: 10,
      })}
    >
      <Box
        sx={{
          px: { md: 20 },
          maxWidth: 1900,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Container;

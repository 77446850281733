import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import createCache from "@emotion/cache";
import theme from "../theme/theme";

export default function ThemeRegistry({ children }) {
  const [cache] = useState(() => {
    const cacheInstance = createCache({ key: "mui" });
    cacheInstance.compat = true;
    return cacheInstance;
  });

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
}

"use client";

import { Box, Typography } from "@mui/material";
import Container from "./container";
import React from "react";

const Mission = () => {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: "center",
          gap: 15,
          justifyContent: "space-between",
        }}
      >
        <Box
          display={"flex"}
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img alt="" src={"/Mission.png"} width={400} height={400} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: { xs: "center", md: "start" },
            flex: 1,
            gap: 3,
            px: { xs: 2, md: 0 },
            maxWidth: 500,
          }}
        >
          <Typography variant="h1">MISSÃO</Typography>
          <Typography variant="h4">
            Ser uma empresa eficaz, marcada pela eficiência de pensar e agir a
            frente do seu próprio ritmo. Inspirando dia a dia experiências novas
            aos nossos consumidores e clientes, garantindo rapidez, fidelidade e
            segurança em nossas entregas para todo o país.
          </Typography>
          <Typography variant="h4">
            Mais do que números, o que nos define é o esforço em expandir e
            tornar a vida das pessoas melhor, oferecendo sempre produtos que
            atendam as mais diversas necessidades no mercado.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Mission;
